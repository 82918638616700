import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import Moment from 'react-moment'
import DownloadCard from '../components/downloadCard'
import Layout from '../components/layout'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export const query = graphql`
  query Ministry($slug: String!) {
    strapiMinistry(slug: {eq: $slug}) {
      updated_at
      name
      description
      downloads {
        content {
          ext
          name
          size
          publicURL
        }
        description
        name
        updated_at
      }
    }
  }
`

const Ministry = ({ data }) => {
  console.log('download',data)
  const ministry = data.strapiMinistry
  return (
    <Layout title={ministry.name} subtitle="HRBC">
      <section id="beliefs" className="page-section cta">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 mx-auto">
              <div className="cta-inner text-center rounded remark-content">
                <ReactMarkdown source={ministry.description} />
                <p className="text-right">
                  <small>Last updated on <Moment format="MMM Do YYYY">{ministry.published_at}</Moment></small>
                </p>
              </div>
            </div>
          </div>
        </div>

        {ministry.downloads.length > 0 ?
          <Container className="mt-5">
            <Row>
              <Col xl={9} className="mx-auto">
                <div className="cta-plain text-center rounded">
                  <h2 className="section-heading mb-4">
                    <span className="section-heading-upper">Related Downloads</span>
                    </h2>
                  {ministry.downloads.map((data, index) => (
                    <DownloadCard 
                      key={index} 
                      name={data.name} 
                      downloadName={data.content.name} 
                      updatedAt={data.updated_at} 
                      size={data.content.size} 
                      ext={data.content.ext} 
                      publicURL={data.content.publicURL} 
                    />
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
          : <></>}
      </section>
    </Layout>
  )
}

export default Ministry
